import {useEffect, useState} from 'react';
import {AlertDialog, Button, Spinner} from 'native-base';

const CustomAlertDialog = ({
  isOpen,
  onClose,
  header,
  text,
  confirmButtonText = 'Okay',
  confirmButtonColorScheme,
  onConfirm = () => {},
  onCancel = onClose,
  isCancelButtonVisible = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    onClose();
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} useRNModal={true}>
      <AlertDialog.Content>
        <AlertDialog.Header>{header}</AlertDialog.Header>
        <AlertDialog.Body>{text}</AlertDialog.Body>
        <AlertDialog.Footer>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button.Group space={2}>
              {
                isCancelButtonVisible && <Button onPress={onCancel} colorScheme="secondary">
                  Cancel
                </Button>
              }
              <Button
                onPress={handleConfirm}
                colorScheme={confirmButtonColorScheme}>
                {confirmButtonText}
              </Button>
            </Button.Group>
          )}
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default CustomAlertDialog;
