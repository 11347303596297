export default function getDepartmentColor(department) {
  if (department === 'Sales') {
    return 'purple';
  } else if (department === 'Parts') {
    return 'yellow';
  } else if (department === 'Service') {
    return 'emerald';
  }
  return 'gray';
}
