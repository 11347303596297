import {useState, useEffect, useCallback, memo} from 'react';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import {Button, HStack, Modal, Popover, Text, VStack} from 'native-base';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import date from 'date-and-time';
import {FaCircleExclamation} from 'react-icons/fa6';
import {BiSolidUpArrow} from 'react-icons/bi';

const containerStyle = {
  width: '100%',
  height: '400px',
};

function RouteMapModal({
  trip,
  routeCoordinates,
  setRouteCoordinates,
  isRouteMapModalOpen,
  setIsRouteMapModalOpen,
}) {
  const [routeCoordinateIndex, setRouteCoordinateIndex] = useState(0);
  const [routeMarks, setRouteMarks] = useState({});
  const [durationInMinutes, setDurationInMinutes] = useState(0);
  const [popupPosition, setPopupPosition] = useState({left: 0});
  const [gpsOffTime, setGpsOffTime] = useState(0);

  useEffect(() => {
    if (!trip || !trip.startTime || !routeCoordinates) return;
    setDurationInMinutes(trip.getTimeElapsedInMinutes());

    const startTime = trip.startTime.toDate();

    const marks = {};
    let gpsOffTimeIterator = 0;
    let gpsOffStartTime = null;

    routeCoordinates.forEach((coordinates, i) => {
      const locationTime = coordinates.timestamp
        ? coordinates.timestamp
        : coordinates.location.timestamp;
      const minutesPast = date
        .subtract(new Date(locationTime), startTime)
        .toMinutes();
      if (coordinates.isGPSDisabled && !gpsOffStartTime) {
        marks[minutesPast] = {
          label: <BiSolidUpArrow color="red" />,
        };

        gpsOffStartTime = new Date(locationTime);
      } else if (coordinates.isGPSDisabled === false) {
        marks[minutesPast] = {
          label: <BiSolidUpArrow color="green" />,
        };

        const gpsOffEndTime = new Date(locationTime);
        if (gpsOffStartTime) {
          gpsOffTimeIterator += gpsOffEndTime - gpsOffStartTime;
          gpsOffStartTime = null;
        }
      } else {
        marks[minutesPast] = {
          label: <></>,
        };
      }
    });

    if (gpsOffStartTime) {
      const gpsOffEndTime = trip.endTime ? trip.endTime.toDate() : new Date();
      gpsOffTimeIterator += gpsOffEndTime - gpsOffStartTime;
      gpsOffStartTime = null;
    }
    setRouteMarks(marks);
    setGpsOffTime(Math.round(gpsOffTimeIterator / 60000));
  }, [routeCoordinates]);

  const updateLocationTime = value => {
    const divLeft = (value / durationInMinutes) * 100 + '%';
    setPopupPosition({left: divLeft});

    const time = date.addMinutes(trip.startTime.toDate(), value);

    const index = routeCoordinates.findIndex(
      coordinate =>
        date.format(new Date(coordinate.timestamp), 'h:mm') ===
        date.format(time, 'h:mm'),
    );
    setRouteCoordinateIndex(index);
  };

  const close = () => {
    setRouteCoordinateIndex(0);
    setRouteMarks({});
    setDurationInMinutes(0);
    setPopupPosition({left: 0});
    setRouteCoordinates();
    setIsRouteMapModalOpen(false);
  };

  if (!trip) {
    return <></>;
  }

  return (
    <Modal
      useRNModal={true}
      size="xl"
      isOpen={isRouteMapModalOpen}
      onClose={close}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Route</Modal.Header>
        <Modal.Body>
          {routeCoordinates && routeCoordinates.length > 0 && (
            <VStack space="4">
              <RouteMap
                routeCoordinates={routeCoordinates}
                index={routeCoordinateIndex}
                isLive={trip.status === 'live'}
              />
              {gpsOffTime > 0 && (
                <Text>
                  <FaCircleExclamation color="red" /> GPS was off for{' '}
                  {gpsOffTime} minutes during the trip.
                </Text>
              )}
              <HStack w="full" space="4" mb="5">
                <Text userSelect="none" fontSize="12">
                  {trip.getStartTimeFormatted()}
                </Text>
                <VStack flex="1" position="relative">
                  <LocationDetailsPopup
                    locationData={routeCoordinates[routeCoordinateIndex]}
                    popupPosition={popupPosition}
                  />
                  <Slider
                    min={0}
                    max={durationInMinutes}
                    defaultValue={0}
                    marks={routeMarks}
                    step={null}
                    onChange={updateLocationTime}
                    dotStyle={{
                      background: '#4c84ed',
                      borderWidth: 0,
                      width: 1,
                      height: 10,
                      top: 0,
                      borderRadius: 0,
                    }}
                    railStyle={{
                      height: 10,
                      backgroundColor: '#fecdd3',
                      borderRadius: 0,
                    }}
                    trackStyle={{
                      height: 10,
                      backgroundColor: '#fecdd3',
                      borderRadius: 0,
                    }}
                    handleStyle={{
                      boxShadow: 'none',
                      borderRadius: 6,
                      borderColor: '#a9a9a9',
                      height: 24,
                      width: 24,
                      background: 'white',
                      opacity: 1,
                    }}
                  />
                </VStack>
                <Text userSelect="none" fontSize="12">
                  {trip.endTime
                    ? trip.getEndTimeFormatted()
                    : date.format(new Date(), 'h:mm A')}
                </Text>
              </HStack>
            </VStack>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

function RouteMap({routeCoordinates, index, isLive}) {
  const [markerPosition, setMarkerPosition] = useState();
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    updateMarkerCoordinates();
  }, [index]);

  const updateMarkerCoordinates = () => {
    if (index >= 0 && routeCoordinates[index]) {
      setMarkerPosition({
        ...routeCoordinates[index],
        lat: routeCoordinates[index].latitude,
        lng: routeCoordinates[index].longitude,
      });
    }
  };

  const polylineRoute = routeCoordinates.map(coordinate => {
    return {
      lat: coordinate.latitude,
      lng: coordinate.longitude,
    };
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      polylineRoute.forEach(point => {
        bounds.extend(point);
      });
      map.fitBounds(bounds);

      setMap(map);
    },
    [map],
  );

  const onUnmount = useCallback(
    function callback(map) {
      setMap(null);
    },
    [map],
  );

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}>
      <Polyline
        path={polylineRoute}
        options={{
          strokeColor: '#4c84ed',
          strokeWeight: 6,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: '#4c84ed',
                fillOpacity: 1,
                scale: 4,
                strokeColor: 'white',
                strokeWeight: 1,
              },
              offset: '100%',
              repeat: '100px',
            },
          ],
        }}
      />
      <Marker
        position={polylineRoute[0]}
        label={{text: 'Start', color: 'white', fontSize: '10px'}}
      />
      {markerPosition && (
        <Marker
          position={markerPosition}
          icon={{
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            fillColor: 'red',
            fillOpacity: 1,
            scale: 6,
            strokeColor: 'white',
            strokeWeight: 1,
            rotation: markerPosition.heading,
          }}
        />
      )}
      {!isLive && (
        <Marker
          position={polylineRoute[polylineRoute.length - 1]}
          label={{text: 'End', color: 'white', fontSize: '10px'}}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

function LocationDetailsPopup({locationData, popupPosition}) {
  if (
    !locationData ||
    !Boolean(locationData.odometer && locationData.timestamp)
  )
    return <></>;
  return (
    <VStack
      style={{
        ...popupPosition,
        transform: `translateX(-50%)`,
      }}
      position="absolute"
      shadow="2"
      minWidth="180px"
      rounded="md"
      bg="yellow.100"
      top="-84px"
      px="4"
      py="2">
      <Text>
        {'Distance: ' + (locationData.odometer / 1000).toFixed(2) + ' KMs '}
        {locationData.isGPSDisabled && <Text color="danger.600">GPS off</Text>}
        {locationData.isGPSDisabled === false && (
          <Text color="success.600">GPS on</Text>
        )}
        <br />
        {locationData.battery &&
          'Battery: ' +
            Math.round(locationData.battery.level * 100) +
            '% ' +
            (locationData.battery.is_charging ? '(Charging)' : '')}
        <br />
        {locationData.timestamp &&
          'Time: ' + date.format(new Date(locationData.timestamp), 'h:mm A')}
      </Text>
    </VStack>
  );
}

export default RouteMapModal;
