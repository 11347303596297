import {useState, useEffect} from 'react';
import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import Onboarding from './pages/Onboarding';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Trips from './pages/Trips';
import EmployeeManagement from './pages/EmployeeManagement';
import EmployeeTrips from './pages/EmployeeTrips';
import Reports from './pages/Reports';
import AccountSettings from './pages/AccountSettings';
import NotFoundPage from './pages/NotFoundPage';
import Loading from './pages/Loading';
import NavbarLayout from './layouts/NavbarLayout';
import LoginLayout from './layouts/LoginLayout';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {auth as firebaseAuth} from './firebase.js';
import {useUser} from './contexts/userContext';
import User from './models/User';
import { useOrganization } from './contexts/OrganizationContext.js';
import {firestore} from './firebase.js';

const router = createBrowserRouter([
  {
    element: <LoginLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'onboarding',
        element: <Onboarding />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'legal',
        element: <TermsOfService />,
      },
    ],
  },
  {
    element: <NavbarLayout />,
    children: [
      {
        path: 'trips',
        element: <Trips />,
      },
      {
        path: 'employees',
        element: <EmployeeManagement />,
      },
      {
        path: 'employees/:employeeId',
        element: <EmployeeTrips />,
      },
      {
        path: 'reports',
        element: <Reports />,
      },
      {
        path: 'settings',
        element: <AccountSettings />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'legal',
        element: <TermsOfService />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

const landingPageRouter = createBrowserRouter([
  {
    element: <LoginLayout />,
    children: [
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'legal',
        element: <TermsOfService />,
      },
    ],
  },
]);

export default function Router() {
  const [authChecked, setAuthChecked] = useState(false);
  const [user, setUser] = useUser();
  const [, setOrganization] = useOrganization();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser && firebaseUser.uid && !firebaseUser.phoneNumber) {
        const newUser = new User({
          department: firebaseUser.uid.split('@')[0],
          organizationId: firebaseUser.uid.split('@')[1],
        });
        setUser(newUser);
        await fetchOrgnaization(newUser.organizationId);
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const fetchOrgnaization = async (orgId) => {
    const organization = (
      await firestore.collection('organizations').doc(orgId).get()
    ).data();
    setOrganization({
      name: organization.name,
      settings: organization.settings,
    });
    return;
  };

  if (window.location.origin.includes('www.tourexpe.com') || (process.env.NODE_ENV === 'development' && window.location.pathname === '/')) {
    return <RouterProvider router={landingPageRouter} />;
  }

  if (!authChecked) {
    return <Loading />;
  }
  return <RouterProvider router={router} />;
}
