import {useEffect, useState} from 'react';
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Heading,
  IconButton,
  Pressable,
  Skeleton,
  Text,
  VStack,
} from 'native-base';
import EmployeeModal from '../components/EmployeeModal';
import {useUser} from '../contexts/userContext';
import {firestore} from '../firebase.js';
import {FaPlus, FaRegPenToSquare} from 'react-icons/fa6';
import {useNavigate} from 'react-router-dom';
import getDepartmentColor from '../utils/getDepartmentColor';
import {useQueryState} from '../utils/useQueryState';

let unsubscribe;
const tabs = ['All', 'Sales', 'Parts', 'Service', 'Others'];

export default function EmployeeManagement() {
  const [user, setUser] = useUser();
  const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isMouseHovering, setIsMouseHovering] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [tab, setTab] = useQueryState('tab');
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState();

  useEffect(() => {
    updateFirestoreListener();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    handleTabUpdate(tab);
  }, [tab, allEmployees]);

  const updateTab = t => {
    if (['Sales', 'Parts', 'Service', 'Others'].includes(t)) {
      setTab(t);
    } else {
      setTab();
    }
  };

  const handleTabUpdate = tab => {
    if (['Sales', 'Parts', 'Service'].includes(tab)) {
      const filteredEmployees = allEmployees.filter(
        employee => employee.department === tab,
      );
      setEmployees(filteredEmployees);
    } else if (tab === 'Others') {
      const filteredEmployees = allEmployees.filter(
        employee => !employee.department,
      );
      setEmployees(filteredEmployees);
    } else {
      setEmployees(allEmployees);
    }
  };

  const updateFirestoreListener = () => {
    setIsLoading(true);
    let query = firestore
      .collection('employees')
      .where('organizationId', '==', user.organizationId);

    if (user.department === 'sales') {
      query = query.where('department', '==', 'Sales');
    } else if (user.department === 'service') {
      query = query.where('department', '==', 'Service');
    } else if (user.department === 'parts') {
      query = query.where('department', '==', 'Parts');
    }

    unsubscribe = query.onSnapshot(employeesSnapshot => {
      const employeesFromDatabase = employeesSnapshot.docs
        .map(doc => {
          return {
            ...doc.data(),
            uid: doc.id,
          };
        })
        .sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

      setAllEmployees(employeesFromDatabase);

      setIsLoading(false);
    });
  };

  const viewTrips = employeeId => {
    navigate(`/employees/${employeeId}`);
  };

  const editEmployee = employee => {
    setEmployeeToEdit(employee);
    setShowEmployeeModal(true);
  };

  return (
    <Center>
      <Container
        w="full"
        maxWidth="896px"
        alignItems="center"
        justifyContent="center"
        pb="6">
        <VStack w="full" space="4">
          <VStack
            w="full"
            space="4"
            position="sticky"
            top="0.4"
            zIndex="1"
            bg="coolGray.100"
            pt="4">
            <HStack justifyContent="space-between" alignItems="end" px="4">
              <Heading>Employees</Heading>
              {user.isAdmin && (
                <Button
                  size="lg"
                  endIcon={<FaPlus color="white" size="16" />}
                  onPress={() => setShowEmployeeModal(true)}>
                  Add new employee
                </Button>
              )}
            </HStack>
            {(user.isAdmin || user.isFinance || user.isModerator) && (
              <HStack borderBottomWidth="1" borderColor="coolGray.300">
                {tabs.map((t, i) => (
                  <Button
                    variant={
                      (!tab && t === 'All') || tab === t ? 'solid' : 'ghost'
                    }
                    onPress={() => updateTab(t)}
                    borderRadius="0"
                    minWidth="84px"
                    key={i}>
                    {t}
                  </Button>
                ))}
              </HStack>
            )}
          </VStack>
          <VStack
            flex="1"
            shadow="1"
            bg="white"
            overflow="hidden"
            rounded="lg"
            mx="1"
            borderColor="coolGray.200"
            position="relative">
            {isLoading
              ? Array.from({length: 6}, (_, i) => (
                  <HStack
                    key={i}
                    borderColor="coolGray.200"
                    borderBottomWidth={i === 5 ? '0' : '1'}
                    p="4"
                    alignItems="center"
                    justifyContent="space-between">
                    <Skeleton h="4" w="64" rounded="full" />
                  </HStack>
                ))
              : employees.map((employee, i) => (
                  <Pressable
                    key={i}
                    onPress={() => viewTrips(employee.uid)}
                    px="2"
                    _hover={{
                      bg: 'warmGray.100',
                    }}
                    borderColor="coolGray.200"
                    borderBottomWidth={i === employees.length - 1 ? '0' : '1'}
                    onMouseEnter={() => setIsMouseHovering(i)}
                    onMouseLeave={() => setIsMouseHovering(null)}>
                    <HStack alignItems="center" justifyContent="space-between">
                      <HStack space="2" p="4">
                        <Text fontSize="md">{employee.name}</Text>
                        <Badge
                          variant="solid"
                          colorScheme={getDepartmentColor(
                            employee.department ?? 'Others',
                          )}
                          rounded="full">
                          {employee.department ?? 'Others'}
                        </Badge>
                      </HStack>
                      {user.isAdmin && (
                        <IconButton
                          display={isMouseHovering === i ? 'flex' : 'none'}
                          icon={<FaRegPenToSquare size="16" color="gray" />}
                          variant="ghost"
                          size="lg"
                          colorScheme="secondary"
                          onPress={() => editEmployee(employee)}
                        />
                      )}
                    </HStack>
                  </Pressable>
                ))}
          </VStack>
        </VStack>
      </Container>
      <EmployeeModal
        showEmployeeModal={showEmployeeModal}
        setShowEmployeeModal={setShowEmployeeModal}
        employeeToEdit={employeeToEdit}
        setEmployeeToEdit={setEmployeeToEdit}
      />
    </Center>
  );
}
