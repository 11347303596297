import {
  Center,
  Container,
  Heading,
  Stack,
  Image,
  Input,
  FormControl,
  Button,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import {useNavigate} from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
      <Center bg="coolGray.100" h="100vh">
        <VStack alignItems="center" space="4">
          <Heading color="gray.600">404</Heading>
          <Text fontSize="lg" color="gray.600">The page you are looking for could not be found!</Text>
          <Button onPress={() => navigate('/trips')} rounded="md" colorScheme="indigo">
            Go to Dashboard
          </Button>
        </VStack>
      </Center>
    );
};

export default NotFoundPage;