import {Outlet, Navigate} from 'react-router-dom';
import {useAuth} from '../auth.js';

export default function Root() {
  const auth = useAuth();

  if (auth.user && !(process.env.NODE_ENV === 'development' && window.location.pathname === '/')) {
    return <Navigate to="/trips" />;
  }
  return <Outlet />;
}
