import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: 'AIzaSyD5S1VWow39uSM3g8jA_VmHW-RiCJvCTuw',
  authDomain: 'trackea-f2596.firebaseapp.com',
  databaseURL:
    'https://trackea-f2596-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'trackea-f2596',
  storageBucket: 'trackea-f2596.appspot.com',
  messagingSenderId: '212220550628',
  appId: '1:212220550628:web:664f2cb2735ba7ff7fbbf1',
  measurementId: 'G-FFFY1DZBT9',
};

firebase.initializeApp(firebaseConfig);
export const admin = firebase;
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const database = firebase.database();
