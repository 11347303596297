import {useEffect, useRef} from 'react';
import {
  Box,
  Divider,
  HStack,
  Text,
  Pressable,
  IconButton,
  VStack,
} from 'native-base';
import {FiArrowLeft} from 'react-icons/fi';
import {FaListUl, FaPeopleGroup, FaUserGear} from 'react-icons/fa6';
import { TbFileAnalytics } from "react-icons/tb";
import {useNavigate, useLocation} from 'react-router-dom';
import { isMobile } from 'react-device-detect';

export default function Sidebar({isOpen, setIsOpen}) {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = event => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navigateTo = path => {
    setIsOpen(false);
    navigate(path);
  };

  return (
    <Box
      tabIndex="2"
      ref={sidebarRef}
      position="fixed"
      w={['80vw', '280px']}
      h="100%"
      zIndex={isOpen && isMobile ? 3 : isOpen ? 1 : -1}
      tabindex={0}
      onBlur={() => setIsOpen(false)}>
      <VStack
        h="100%"
        bg={isMobile ? "white" : "coolGray.200"}
        top="0"
        left="0"
        bottom="0"
        w="full"
        position="sticky"
        py="4"
        style={{
          transition: 'transform 0.2s ease',
          transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
        }}>
        <IconButton
          variant="ghost"
          size="lg"
          icon={<FiArrowLeft size="24" />}
          colorScheme="light"
          onPress={() => setIsOpen(false)}
          display="inline-block"
        />
        <VStack space="2">
          <Pressable
            overflow="hidden"
            onPress={() => navigateTo('/trips')}>
            {({isHovered}) => (
              <Box p="4" w="full" bg={isHovered ? 'muted.100' : ''}>
                <HStack
                  color={
                    location.pathname === '/trips'
                      ? 'primary.700'
                      : 'muted.600'
                  }
                  items="baseline"
                  space="4">
                  <FaListUl size="24" />
                  <Text
                    color={
                      location.pathname === '/trips'
                        ? 'primary.700'
                        : 'muted.600'
                    }
                    fontWeight="medium"
                    fontSize="md">
                    All trips
                  </Text>
                </HStack>
              </Box>
            )}
          </Pressable>
          <Pressable
            overflow="hidden"
            onPress={() => navigateTo('/employees')}>
            {({isHovered}) => (
              <Box p="4" w="full" bg={isHovered ? 'muted.100' : ''}>
                <HStack
                  color={
                    location.pathname === '/employees'
                      ? 'primary.700'
                      : 'muted.600'
                  }
                  items="baseline"
                  space="4">
                  <FaPeopleGroup size="24" />
                  <Text
                    color={
                      location.pathname === '/employees'
                        ? 'primary.700'
                        : 'muted.600'
                    }
                    fontWeight="medium"
                    fontSize="md">
                    Employee management
                  </Text>
                </HStack>
              </Box>
            )}
          </Pressable>
          <Pressable
            overflow="hidden"
            onPress={() => navigateTo('/reports')}>
            {({isHovered}) => (
              <Box p="4" w="full" bg={isHovered ? 'muted.100' : ''}>
                <HStack
                  color={
                    location.pathname === '/reports'
                      ? 'primary.700'
                      : 'muted.600'
                  }
                  items="baseline"
                  space="4">
                  <TbFileAnalytics size="24" />
                  <Text
                    color={
                      location.pathname === '/reports'
                        ? 'primary.700'
                        : 'muted.600'
                    }
                    fontWeight="medium"
                    fontSize="md">
                    Reports
                  </Text>
                </HStack>
              </Box>
            )}
          </Pressable>
          <Pressable
            overflow="hidden"
            onPress={() => navigateTo('/settings')}>
            {({isHovered}) => (
              <Box p="4" w="full" bg={isHovered ? 'muted.100' : ''}>
                <HStack
                  color={
                    location.pathname === '/settings'
                      ? 'primary.700'
                      : 'muted.600'
                  }
                  items="baseline"
                  space="4">
                  <FaUserGear size="24" />
                  <Text
                    color={
                      location.pathname === '/settings'
                        ? 'primary.700'
                        : 'muted.600'
                    }
                    fontWeight="medium"
                    fontSize="md">
                    Account Settings
                  </Text>
                </HStack>
              </Box>
            )}
          </Pressable>
        </VStack>
      </VStack>
    </Box>
  );
}
