import {Button, HStack, IconButton, Text, Image} from 'native-base';
import {MdMenu} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import {auth as firebaseAuth} from '../firebase.js';
import {useAuth} from '../auth.js';
import {useUser} from '../contexts/userContext';
import {isMobile} from 'react-device-detect';

export default function AppBar({openSidebar}) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useUser();

  const logout = async () => {
    auth.logout();
    await firebaseAuth.signOut();
    navigate('/login');
  };

  const getUserDepartmentTitle = () => {
    switch (user.department) {
      case 'admin':
        return 'Admin';
        break;
      case 'moderator':
        return 'Moderator';
        break;
      case 'sales':
        return 'Sales Team';
        break;
      case 'service':
        return 'Service Team';
        break;
      case 'parts':
        return 'Parts Team';
        break;
      case 'accounts':
      case 'finance':
        return 'Finance department';
        break;
      default:
        return 'Others';
    }
  };

  return (
    <>
      <HStack
        zIndex="2"
        position="sticky"
        top="0"
        bg="white"
        h="16"
        justifyContent="space-between"
        alignItems="center"
        borderColor="coolGray.200"
        borderBottomWidth="1"
        w="100%">
        <HStack>
        {
          isMobile ? <IconButton
            variant="ghost"
            colorScheme="light"
            icon={<MdMenu size="24" />}
            onPress={openSidebar}
          /> : <HStack bg="black" minW="280px" px="4" space="1" alignItems="center" justifyContent="center" h="16">
          <Image
            size="lg"
            mt="1"
            resizeMode="contain"
            src="tourexpe_logo.png"
            alt="tourexpe logo"
          />
          <Image
            size="lg"
            resizeMode="contain"
            src="tourexpe_console_logo.png"
            alt="tourexpe console logo"
          />
          </HStack>
        }
        </HStack>
        <HStack alignItems="baseline" space="4" px="4">
          <Text bg="gray.100" p="2" px="4" rounded="md">{getUserDepartmentTitle()}</Text>
          <Button colorScheme="light" onPress={logout} variant="ghost">
            Logout
          </Button>
        </HStack>
      </HStack>
    </>
  );
}
