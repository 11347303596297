export default function generateNewOrganizationId() {
  const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  let id = '';

  for (let i = 0; i < 3; i++) {
    id += alphabets.charAt(Math.floor(Math.random() * alphabets.length));
  }

  for (let i = 0; i < 3; i++) {
    id += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  return id;
}