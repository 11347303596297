import {createContext, useContext, useState} from 'react';

const UserContext = createContext();

function UserProvider({children}) {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);
  if (context) return context;
}

export {UserProvider, useUser};
