import {firestore} from '../firebase.js';
import Trip from '../models/Trip';
import date from 'date-and-time';

export default async function getTripsData(
  dateRange,
  organizationId,
  departments,
  statuses,
) {
  let query = firestore
    .collection('trips')
    .where('user.organizationId', '==', organizationId)
    .where('status', 'in', statuses)
    .where('submitTime', '>=', dateRange[0])
    .where('submitTime', '<=', dateRange[1]);

  if (departments.length === 1) {
    query = query.where('user.department', '==', departments[0]);
  }

  const tripsSnapshot = await query.get();

  const trips = tripsSnapshot.docs.map(doc => {
    return new Trip({
      id: doc.id,
      ...doc.data(),
    });
  });

  const employeesData = {};
  trips.forEach(trip => {
    const amount = Math.ceil(trip.getTotalAllowance());
    const tripDate = date.format(trip.submitTime.toDate(), 'DD-MM-YYYY');
    if (employeesData[trip.user.id]) {
      if (employeesData[trip.user.id][tripDate]) {
        employeesData[trip.user.id][tripDate] += amount;
      } else {
        employeesData[trip.user.id][tripDate] = amount;
      }
    } else {
      employeesData[trip.user.id] = {
        name: trip.user.name,
        [tripDate]: amount,
      };
    }
  });
  return employeesData;
}
