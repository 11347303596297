import {useState} from 'react';
import {VStack, View} from 'native-base';
import {Outlet} from 'react-router-dom';
import AppBar from '../components/AppBar';
import Sidebar from '../components/Sidebar';
import {RequireAuth} from '../RequireAuth';
import {isMobile} from 'react-device-detect';

export default function NavbarLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  return (
    <RequireAuth>
      <VStack
        zIndex="-1"
        position="absolute"
        bg="coolGray.100"
        top="0"
        bottom="0"
        left="0"
        right="0"
        h="100vh">
        {isSidebarOpen && isMobile && <View zIndex="3" position="absolute" bg="black:alpha.20" h="100vh" w="100%" />}
        <AppBar openSidebar={openSidebar} />
        <Sidebar isOpen={isMobile ? isSidebarOpen : true} setIsOpen={setIsSidebarOpen} />
        <VStack ml={[0 ,'300px']} flex="1" overflow="auto" pb="6">
          <Outlet />
        </VStack>
      </VStack>
    </RequireAuth>
  );
}
