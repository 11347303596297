import {useEffect, useState} from 'react';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  TextArea,
  VStack,
} from 'native-base';
import {admin, firestore} from '../firebase.js';

export default function EditTripInfoModal({
  trip,
  isEditTripInfoModalOpen,
  setIsEditTripInfoModalOpen,
}) {
  const [rectifiedDistance, setRectifiedDistance] = useState(0);
  const [isRectifyingDistance, setIsRectifyingDistance] = useState(false);
  const [da, setDa] = useState(0);
  const [isRectifyingDa, setIsRectifyingDa] = useState(false);
  const [remarks, setRemarks] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [rectifiedTravelExpenses, setRectifiedTravelExpenses] = useState({});

  useEffect(() => {
    setRectifiedDistance(
      trip &&
        (!isNaN(trip.rectifiedDistance)
          ? trip.rectifiedDistance
          : trip.claimedDistance || trip.distanceTravelled),
    );
    setIsRectifyingDistance(trip && !isNaN(trip.rectifiedDistance));
    setDa(
      trip &&
        (!isNaN(trip.rectifiedDa)
          ? trip.rectifiedDa
          : trip.getDA(Boolean(trip.claimedDistance))),
    );
    setIsRectifyingDa(trip?.rectifiedDa && !isNaN(trip.rectifiedDa));
    if (trip && trip.travelExpenses) {
      const exp = {};
      trip.travelExpenses.forEach((e, i) => {
        if (!isNaN(e.rectifiedAmount)) {
          exp[i] = e;
        }
      });
      setRectifiedTravelExpenses(exp);
    }
    setRemarks(trip && trip.remarks ? trip.remarks : '');
  }, [isEditTripInfoModalOpen]);

  const updateRectifiedDistance = text => {
    if (text.trim().length === 0) {
      setRectifiedDistance(0);
      return;
    }
    if (/[^0-9.]/g.test(text)) {
      return;
    }
    const floatDistance = parseFloat(text.replace(/[^0-9.]/g, ''));
    setRectifiedDistance(floatDistance);
  };

  const updateRectifiedTravelExpenses = (text, i) => {
    const expenses = {...rectifiedTravelExpenses};
    if (text.trim().length === 0) {
      expenses[i].rectifiedAmount = 0;
      setRectifiedTravelExpenses(expenses);
      return;
    }
    if (/[^0-9.]/g.test(text)) {
      return;
    }
    expenses[i].rectifiedAmount = parseFloat(text.replace(/[^0-9.]/g, ''));
    setRectifiedTravelExpenses(expenses);
  };

  const updateDa = text => {
    if (text.trim().length === 0) {
      setDa(0);
      return;
    }
    if (/[^0-9.]/g.test(text)) {
      return;
    }
    const floatDistance = parseFloat(text.replace(/[^0-9.]/g, ''));
    setDa(floatDistance);
  };

  const updateRemarks = text => {
    if (text.trim().length === 0) {
      setRemarks();
      return;
    }
    setRemarks(text);
  };

  const isRectifiedDistanceInvalid = rectifiedDistance > 2000;
  const isRectifiedDaInvalid = da > 5000;
  const isRemarksInvalid = remarks && remarks.length > 200;

  const save = async () => {
    setIsSaving(true);
    const updateMap = {};
    updateMap.rectifiedDistance = isRectifyingDistance
      ? rectifiedDistance
      : admin.firestore.FieldValue.delete();

    updateMap.rectifiedDa = isRectifyingDa
      ? da
      : admin.firestore.FieldValue.delete();

    if (trip.travelExpenses) {
      updateMap.travelExpenses = trip.travelExpenses.map(e => {
        const ex = {
          expenseName: e.expenseName,
          amount: e.amount,
        };
        if (e.receiptImage) {
          ex.receiptImage = e.receiptImage;
        }
        return ex;
      });

      Object.keys(rectifiedTravelExpenses).forEach(i => {
        updateMap.travelExpenses[i] = rectifiedTravelExpenses[i];
      });
    }

    if (remarks) {
      updateMap.remarks = remarks;
    }
    await firestore.collection('trips').doc(trip.id).update(updateMap);
    setIsEditTripInfoModalOpen(false);
    setIsSaving(false);
  };

  if (!trip) {
    return <></>;
  }
  return (
    <Modal
      useRNModal={true}
      size="xl"
      isOpen={isEditTripInfoModalOpen}
      onClose={() => setIsEditTripInfoModalOpen(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Edit Trip</Modal.Header>
        <Modal.Body>
          <FormControl>
            <VStack space="2">
              <HStack space="4" alignItems="center">
                <FormControl.Label justifyContent="end" flex="1">
                  Name
                </FormControl.Label>
                <Input flex="2" value={trip.user.name} isDisabled="true" />
              </HStack>
              <HStack space="4" alignItems="center">
                <FormControl.Label justifyContent="end" flex="1">
                  Date
                </FormControl.Label>
                <Input
                  flex="2"
                  value={trip.getFullStartTimeFormatted()}
                  isDisabled="true"
                />
              </HStack>
              {trip.vehicleType !== 'Public transport' && (
                <VStack
                  space="2"
                  borderColor={isRectifyingDistance && 'muted.200'}
                  borderWidth={isRectifyingDistance && '1'}
                  borderRadius={isRectifyingDistance && '4'}
                  p={isRectifyingDistance && '2'}>
                  <HStack space="4" alignItems="center">
                    <FormControl.Label justifyContent="end" flex="1">
                      Calculated Distance(KMs)
                    </FormControl.Label>
                    <HStack flex="2" space="2">
                      <Input
                        flex="1"
                        value={trip.distanceTravelled.toFixed(2)}
                        isDisabled="true"
                      />
                      {!isRectifyingDistance && (
                        <Button
                          size="xs"
                          onPress={() => setIsRectifyingDistance(true)}>
                          Rectify
                        </Button>
                      )}
                    </HStack>
                  </HStack>
                  {trip.claimedDistance && (
                    <HStack space="4" alignItems="center">
                      <FormControl.Label justifyContent="end" flex="1">
                        Claimed Distance(KMs)
                      </FormControl.Label>
                      <Input
                        flex="2"
                        value={trip.claimedDistance.toFixed(2)}
                        isDisabled="true"
                      />
                    </HStack>
                  )}
                  {isRectifyingDistance && (
                    <HStack space="4" alignItems="center">
                      <FormControl.Label justifyContent="end" flex="1">
                        Rectified Distance(KMs)
                      </FormControl.Label>
                      <HStack flex="2" space="2">
                        <FormControl
                          flex="2"
                          isInvalid={isRectifiedDistanceInvalid}>
                          <Input
                            autoFocus
                            placeholder="Input distance"
                            onChangeText={updateRectifiedDistance}
                            value={rectifiedDistance}
                            isDisabled={isSaving}
                          />
                          <FormControl.ErrorMessage>
                            Distance should not be more than 2000 KMs.
                          </FormControl.ErrorMessage>
                        </FormControl>
                        <Button
                          colorScheme="secondary"
                          size="xs"
                          onPress={() => setIsRectifyingDistance(false)}>
                          X
                        </Button>
                      </HStack>
                    </HStack>
                  )}
                </VStack>
              )}
              {trip.travelExpenses &&
                trip.travelExpenses.map((expense, i) => {
                  return (
                    <VStack
                      space="2"
                      key={i}
                      borderColor={rectifiedTravelExpenses[i] && 'muted.200'}
                      borderWidth={rectifiedTravelExpenses[i] && '1'}
                      borderRadius={rectifiedTravelExpenses[i] && '4'}
                      p={rectifiedTravelExpenses[i] && '2'}>
                      <HStack space="4" alignItems="center">
                        <FormControl.Label justifyContent="end" flex="1">
                          {expense.expenseName}(₹)
                        </FormControl.Label>
                        <HStack flex="2" space="2">
                          <Input
                            flex="1"
                            value={expense.amount}
                            isDisabled="true"
                          />
                          {!rectifiedTravelExpenses[i] && (
                            <Button
                              size="xs"
                              onPress={() =>
                                setRectifiedTravelExpenses({
                                  ...rectifiedTravelExpenses,
                                  [i]: {
                                    ...expense,
                                    rectifiedAmount: expense.amount,
                                  },
                                })
                              }>
                              Rectify
                            </Button>
                          )}
                        </HStack>
                      </HStack>
                      {rectifiedTravelExpenses[i] && (
                        <HStack space="4" alignItems="center" key={i}>
                          <FormControl.Label justifyContent="end" flex="1">
                            Rectified amount(₹)
                          </FormControl.Label>
                          <HStack flex="2" space="2">
                            <Input
                              flex="1"
                              placeholder="Input amount"
                              onChangeText={t =>
                                updateRectifiedTravelExpenses(t, i)
                              }
                              value={rectifiedTravelExpenses[i].rectifiedAmount}
                              isDisabled={isSaving}
                            />
                            <Button
                              colorScheme="secondary"
                              size="xs"
                              onPress={() => {
                                const temp = {...rectifiedTravelExpenses};
                                delete temp[i];
                                setRectifiedTravelExpenses(temp);
                              }}>
                              X
                            </Button>
                          </HStack>
                        </HStack>
                      )}
                    </VStack>
                  );
                })}
              <VStack
                space="2"
                p={isRectifyingDa && '2'}
                borderColor={isRectifyingDa && 'muted.200'}
                borderWidth={isRectifyingDa && '1'}
                borderRadius={isRectifyingDa && '4'}>
                <HStack space="4" alignItems="center">
                  <FormControl.Label justifyContent="end" flex="1">
                    Calculated D.A.(₹)
                  </FormControl.Label>
                  <HStack flex="2" space="2">
                    <Input
                      flex="1"
                      value={trip.getOriginalDA().toFixed(2)}
                      isDisabled="true"
                    />
                    {!isRectifyingDa && (
                      <Button size="xs" onPress={() => setIsRectifyingDa(true)}>
                        Rectify
                      </Button>
                    )}
                  </HStack>
                </HStack>
                {isRectifyingDa && (
                  <HStack space="4" alignItems="center">
                    <FormControl.Label justifyContent="end" flex="1">
                      Rectified D.A.(₹)
                    </FormControl.Label>
                    <HStack flex="2" space="2">
                      <FormControl flex="1" isInvalid={isRectifiedDaInvalid}>
                        <Input
                          placeholder="Input amount"
                          onChangeText={updateDa}
                          value={da}
                          isDisabled={isSaving}
                        />
                        <FormControl.ErrorMessage>
                          D.A. should not be more than ₹5000.
                        </FormControl.ErrorMessage>
                      </FormControl>
                      <Button
                        colorScheme="secondary"
                        size="xs"
                        onPress={() => setIsRectifyingDa(false)}>
                        X
                      </Button>
                    </HStack>
                  </HStack>
                )}
              </VStack>
              <HStack space="4" alignItems="start">
                <FormControl.Label justifyContent="end" flex="1">
                  Remarks
                </FormControl.Label>
                <FormControl flex="2" isInvalid={isRemarksInvalid}>
                  <TextArea
                    placeholder="Remarks"
                    onChangeText={updateRemarks}
                    value={remarks}
                    isDisabled={isSaving}
                  />
                  <FormControl.ErrorMessage>
                    Text too long!
                  </FormControl.ErrorMessage>
                </FormControl>
              </HStack>
            </VStack>
          </FormControl>
          <VStack alignItems="end" mt="4">
            <Button
              px="6"
              isDisabled={
                isRectifiedDistanceInvalid ||
                isRectifiedDaInvalid ||
                isRemarksInvalid
              }
              onPress={save}
              isLoading={isSaving}
              isLoadingText="Saving">
              Save
            </Button>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
