import {useEffect, useState} from 'react';
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Modal,
} from 'native-base';
import {FaCheck, FaPlus} from 'react-icons/fa6';
import {firestore} from '../firebase.js';
import firebase from 'firebase/compat/app';
import {useUser} from '../contexts/userContext';
import CustomAlertDialog from './CustomAlertDialog';

export default function EmployeeModal({
  showEmployeeModal,
  setShowEmployeeModal,
  employeeToEdit,
  setEmployeeToEdit,
}) {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [department, setDepartment] = useState('Sales');
  const [level, setLevel] = useState('L5');
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [user, setUser] = useUser();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState({});

  useEffect(() => {
    if (showEmployeeModal && employeeToEdit) {
      setName(employeeToEdit.name);
      setPhoneNumber(employeeToEdit.phoneNumber.slice(3));
      setDepartment(employeeToEdit.department);
      setLevel(employeeToEdit.level);
    } else {
      setName('');
      setPhoneNumber('');
      setDepartment('Sales');
      setLevel('L5');
      setEmployeeToEdit();
    }
  }, [showEmployeeModal]);

  const updatePhoneNumber = text => {
    setPhoneNumber(text);
  };

  const isPhoneNumberAlreadyInUse = async phoneNumber => {
    const employeesSnapshot = await firestore
      .collection('employees')
      .where('phoneNumber', '==', phoneNumber)
      .get();
    return employeesSnapshot.docs.length > 0
      ? employeesSnapshot.docs[0].data()
      : null;
  };

  const notifyPhoneNumberAlreadyInUse = alreadyExistingUser => {
    setIsAlertDialogOpen(true);
    setAlertDialogState({
      header: 'Error',
      text:
        `Phone number ${alreadyExistingUser.phoneNumber} is already in use by ${
          alreadyExistingUser.name
        } (${alreadyExistingUser.department || 'Others'}).` +
        `\n` +
        `Please delete that user record before allotting this phone number to the new employee.`,
      confirmButtonText: 'Okay',
      onConfirm: () => setIsAlertDialogOpen(false),
      isCancelButtonVisible: false,
    });
  };

  const save = async () => {
    setIsSaving(true);
    const formattedPhoneNumber = '+91' + phoneNumber.trim();
    if (employeeToEdit) {
      await firestore
        .collection('employees')
        .doc(employeeToEdit.uid)
        .update({
          name: name.trim(),
          phoneNumber: formattedPhoneNumber,
          department,
          level,
          organizationId: user.organizationId,
        });
    } else {
      const alreadyExistingUser = await isPhoneNumberAlreadyInUse(
        formattedPhoneNumber,
      );
      if (alreadyExistingUser) {
        notifyPhoneNumberAlreadyInUse(alreadyExistingUser);
        setIsSaving(false);
        return;
      }
      await firestore.collection('employees').add({
        name: name.trim(),
        phoneNumber: formattedPhoneNumber,
        department,
        level,
        organizationId: user.organizationId,
      });
    }

    setEmployeeToEdit();
    setShowEmployeeModal(false);
    setIsSaving(false);
  };

  const askToConfirmDelete = () => {
    setIsAlertDialogOpen(true);
    setAlertDialogState({
      header: 'Confirm delete',
      text: `Are you sure you want to delete ${employeeToEdit.name}?`,
      confirmButtonText: 'Delete',
      confirmButtonColorScheme: 'danger',
      onConfirm: deleteEmployee,
    });
  };

  const deleteEmployee = async () => {
    setIsDeleting(true);
    await firestore.collection('employees').doc(employeeToEdit.uid).delete();
    setEmployeeToEdit();
    setShowEmployeeModal(false);
    setIsDeleting(false);
  };

  return (
    <>
      <Modal
        isOpen={showEmployeeModal}
        onClose={() => setShowEmployeeModal(false)}
        useRNModal={true}
        size="xl">
        <Modal.Content>
          <Modal.Header>
            {employeeToEdit ? 'Edit Employee' : 'New Employee'}
          </Modal.Header>
          <Modal.Body>
            <FormControl>
              <FormControl.Label>Name</FormControl.Label>
              <Input
                isDisabled={isSaving || employeeToEdit}
                placeholder="Name"
                value={name}
                onChangeText={setName}
              />
            </FormControl>
            <FormControl mt="3">
              <FormControl.Label>Phone number</FormControl.Label>
              <InputGroup>
                <InputLeftAddon children={'+91'} />
                <Input
                  isDisabled={isSaving || employeeToEdit}
                  width="92%"
                  placeholder="Phone number"
                  value={phoneNumber}
                  onChangeText={updatePhoneNumber}
                />
              </InputGroup>
            </FormControl>
            <FormControl mt="3">
              <FormControl.Label>Department</FormControl.Label>
              <Select
                isDisabled={isSaving}
                selectedValue={department}
                minWidth="200"
                accessibilityLabel="Choose Department"
                placeholder="Choose Department"
                _selectedItem={{
                  endIcon: <FaCheck />,
                }}
                onValueChange={itemValue => setDepartment(itemValue)}>
                <Select.Item label="Sales" value="Sales" />
                <Select.Item label="Service" value="Service" />
                <Select.Item label="Parts" value="Parts" />
                <Select.Item label="Others" value="Other" />
              </Select>
            </FormControl>
            <FormControl mt="3">
              <FormControl.Label>Level</FormControl.Label>
              <Select
                isDisabled={isSaving}
                selectedValue={level}
                minWidth="200"
                accessibilityLabel="Choose Level"
                placeholder="Choose Level"
                _selectedItem={{
                  endIcon: <FaCheck />,
                }}
                onValueChange={itemValue => setLevel(itemValue)}>
                <Select.Item label="L5" value="L5" />
                <Select.Item label="L6" value="L6" />
                <Select.Item label="L7" value="L7" />
              </Select>
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                isDisabled={isSaving || isDeleting}
                onPress={() => {
                  setShowEmployeeModal(false);
                }}>
                Cancel
              </Button>
              {employeeToEdit && (
                <Button
                  colorScheme="danger"
                  isLoadingText="Deleting"
                  isLoading={isDeleting}
                  isDisabled={isSaving}
                  onPress={askToConfirmDelete}>
                  Delete
                </Button>
              )}
              <Button
                isDisabled={name.length === 0 || phoneNumber.length <= 3}
                onPress={save}
                isDisabled={isDeleting}
                isLoadingText="Saving"
                isLoading={isSaving}>
                {employeeToEdit ? 'Update' : 'Save'}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <CustomAlertDialog
        isOpen={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        header={alertDialogState.header}
        text={alertDialogState.text}
        confirmButtonText={alertDialogState.confirmButtonText}
        confirmButtonColorScheme={alertDialogState.confirmButtonColorScheme}
        onConfirm={alertDialogState.onConfirm}
        isCancelButtonVisible={alertDialogState.isCancelButtonVisible}
      />
    </>
  );
}
