import {
  Box,
  Container,
  Heading,
  HStack,
  VStack,
  Stack,
  Text,
  Link,
  Button,
  Menu,
  Pressable,
  Divider,
  useTheme,
} from 'native-base';
import {
  MdMenu,
  MdCall,
  MdPayments,
  MdInsertChartOutlined,
} from 'react-icons/md';
import {PiMapPinFill} from 'react-icons/pi';
import {useRef} from 'react';

export default function Component() {
  const {colors} = useTheme();
  const features = useRef();
  const platform = useRef();

  const scrollTo = element => {
    element.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <Box alignItems="center">
      <HStack position="fixed" top="0" w="full" h="20" zIndex="1">
        <Box h="full" bg="black" w="30%" />
        <Box h="full" bg="white" w="70%" />
      </HStack>
      <Box position="sticky" top="0" w="full" zIndex="1" alignItems="center">
        <Container w="full" maxWidth={{base: 'full', xl: '1280px'}}>
          <HStack
            h="20"
            alignItems="center"
            justifyContent="space-between"
            w="full">
            <Box
              w={{base: '84%', md: '84%', lg: '30%'}}
              h="full"
              bg="black"
              justifyContent="center">
              <Link href="" w="full" justifyContent="center">
                <img
                  alt="Tourexpe logo"
                  src="tourexpe_logo.png"
                  height="48px"
                />
              </Link>
            </Box>
            <Box
              w={{base: '16%', md: '16%', lg: 'auto'}}
              justifyContent="center">
              <HStack
                space={[4, 6]}
                pr="16"
                alignItems="center"
                display={{base: 'none', md: 'none', lg: 'flex'}}>
                <Link
                  onPress={() => {
                    scrollTo(features);
                  }}
                  _text={{fontSize: 'lg', textDecoration: 'none'}}>
                  Features
                </Link>
                <Link
                  onPress={() => {
                    scrollTo(platform);
                  }}
                  _text={{fontSize: 'lg', textDecoration: 'none'}}>
                  Platform
                </Link>
                <Link
                  onPress={() => window.open('tel:+919109402484')}
                  _text={{fontSize: 'lg', textDecoration: 'none'}}>
                  Contact Us
                </Link>
                <Button
                  colorScheme="indigo"
                  px="4"
                  onPress={() => {
                    window.location = 'https://console.tourexpe.com/login';
                  }}>
                  <Text fontSize="lg" color="white">
                    Sign In
                  </Text>
                </Button>
              </HStack>
              <Menu
                w="200px"
                trigger={triggerProps => {
                  return (
                    <Pressable
                      display={{base: 'flex', lg: 'none'}}
                      justifyContent="center"
                      alignItems="center"
                      accessibilityLabel="More options menu"
                      {...triggerProps}>
                      <MdMenu size="32" />
                    </Pressable>
                  );
                }}>
                <Menu.Item>
                  <Text
                    onPress={() => {
                      scrollTo(features);
                    }}
                    fontSize="lg"
                    fontWeight="500">
                    Features
                  </Text>
                </Menu.Item>
                <Menu.Item>
                  <Text
                    onPress={() => {
                      scrollTo(platform);
                    }}
                    fontSize="lg"
                    fontWeight="500">
                    Platform
                  </Text>
                </Menu.Item>
                <Menu.Item>
                  <Text
                    onPress={() => window.open('tel:+919109402484')}
                    fontSize="lg"
                    fontWeight="500">
                    Contact Us
                  </Text>
                </Menu.Item>
                <Divider my="1" w="100%" />
                <Menu.Item
                  onPress={() => {
                    window.location = 'https://console.tourexpe.com/login';
                  }}>
                  <Text color="indigo.700" fontSize="lg" fontWeight="500">
                    Sign In
                  </Text>
                </Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Container>
      </Box>

      <Box bg="white" w="full" alignItems="center">
        <Container maxWidth={{base: '80%', lg: '1024px'}}>
          <Stack
            direction={{base: 'col', md: 'col', lg: 'row'}}
            w="full"
            py="16"
            space="10">
            <VStack
              flex="1"
              space="8"
              justifyContent="center"
              mb={{base: '4', md: '4', lg: '0'}}>
              <Heading size="2xl" color="indigo.600">
                Daily T.A./D.A. settlement made easy
              </Heading>
              <Text color="gray.600" fontSize="lg">
                Presenting you the quickest and hassle-free way to manage
                T.A./D.A. payments. Tourexpe is designed to make handling travel
                allowances, real-time staff tracking, and periodically report
                generation a seamless experience for your company. Explore the
                power of Tourexpe and elevate your business operations.
              </Text>
              <Button
                colorScheme="indigo"
                size="lg"
                w={{base: '180px', md: '180px', lg: '256px'}}
                onPress={() => window.open('tel:+919109402484')}
                borderRadius="lg">
                <HStack space="2" color="white" alignItems="center">
                  <MdCall size="20" />
                  Contact Us
                </HStack>
              </Button>
            </VStack>
            <VStack flex="1">
              <img alt="landing page" src="landing_page_vector.jpg" />
            </VStack>
          </Stack>
        </Container>
      </Box>

      <Box bg="gray.100" w="full" alignItems="center">
        <Container maxWidth={{base: '80%', lg: '1024px'}}>
          <VStack
            py="16"
            alignItems="center"
            justifyContent="center"
            w="full"
            space="12">
            <VStack alignItems="center" justifyContent="center" space="4">
              <Heading size="xl" color="indigo.600">
                Trusted by Our Users
              </Heading>
              <Text color="gray.600" fontSize="2xl">
                Trusted by <Text color="error.700">Suryakiran Group</Text>, Tata
                Hitachi Dealers, Raipur
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Box>

      <Box
        style={{scrollMargin: '48px'}}
        ref={features}
        w="full"
        alignItems="center">
        <Container maxWidth={{base: '80%', lg: '1024px'}}>
          <VStack
            py="16"
            alignItems="center"
            justifyContent="center"
            w="full"
            space="12">
            <VStack alignItems="center" justifyContent="center" space="4">
              <Text
                bg="indigo.200"
                p="2"
                px="4"
                borderRadius="lg"
                color="indigo.700"
                fontSize="md">
                Key Features
              </Text>
              <Heading size="xl" color="indigo.600" textAlign="center">
                Streamline Your Operations
              </Heading>
              <Text color="gray.600" fontSize="xl" textAlign="center">
                Our tool provides the essential features to help you manage the
                T.A./D.A. payments for your field staff efficiently.
              </Text>
            </VStack>
            <VStack w="full" space="8">
              <Stack
                direction={{base: 'col', md: 'col', lg: 'row'}}
                space="8"
                w="full"
                justifyContent="center">
                <VStack
                  space="2"
                  maxWidth="20rem"
                  textAlign={{base: 'left', md: 'left', lg: 'right'}}
                  alignItems={{
                    base: 'flex-start',
                    md: 'flex-start',
                    lg: 'flex-end',
                  }}>
                  <PiMapPinFill size="32" color={colors.indigo[500]} />
                  <Heading size="md" color="indigo.600">
                    Real-time GPS Tracking
                  </Heading>
                  <Text color="gray.600" fontSize="md">
                    Monitor the locations of your field staff in real time. With
                    Tourexpe, you stay connected and in control, ensuring
                    optimal efficiency in your operations. The distance
                    calculation through the Fieldmate app calculates the Travel
                    allowance with ease and accuracy.
                  </Text>
                </VStack>
                <VStack>
                  <img
                    alt="GPS Tracking"
                    src="./assets/gps_tracking.png"
                    height="400px"
                    style={{
                      objectFit: 'contain',
                      filter: 'drop-shadow(2px 6px 4px #a2a2a2)',
                    }}
                  />
                </VStack>
              </Stack>
              <Stack
                direction={{
                  base: 'column-reverse',
                  md: 'column-reverse',
                  lg: 'row',
                }}
                space="8"
                w="full"
                justifyContent="center">
                <VStack>
                  <img
                    alt="Allowance Calculations"
                    src="./assets/allowance_calculations.png"
                    height="400px"
                    style={{
                      objectFit: 'contain',
                      filter: 'drop-shadow(2px 6px 4px #a2a2a2)',
                    }}
                  />
                </VStack>
                <VStack space="2" maxWidth="20rem">
                  <MdPayments size="32" color={colors.indigo[500]} />
                  <Heading size="md" color="indigo.600">
                    Automated Allowance Calculations
                  </Heading>
                  <Text color="gray.600" fontSize="md">
                    Tourexpe automatically calculates T.A. and D.A. depending up
                    on the distance travelled and the hours spend on-duty as per
                    the company norms. This greatly reduces administrative
                    burdens and ensuring accurate reimbursements.
                  </Text>
                </VStack>
              </Stack>
              <Stack
                direction={{base: 'col', md: 'col', lg: 'row'}}
                space="8"
                w="full"
                justifyContent="center">
                <VStack
                  space="2"
                  maxWidth="20rem"
                  textAlign={{base: 'left', md: 'left', lg: 'right'}}
                  alignItems={{
                    base: 'flex-start',
                    md: 'flex-start',
                    lg: 'flex-end',
                  }}>
                  <MdInsertChartOutlined size="32" color={colors.indigo[500]} />
                  <Heading size="md" color="indigo.600">
                    Performance Analytics
                  </Heading>
                  <Text color="gray.600" fontSize="md">
                    Track performance of each employee in the given period by
                    generating financial reports. Our report generation feature
                    help you manage payments, keep track of type of services
                    performed(ASC, MSI, Commisioning, FM etc.) my the service
                    engineers. It also allows exporting the leads generated by
                    the field employees.
                  </Text>
                </VStack>
                <VStack>
                  <img
                    alt="Analytics"
                    src="./assets/analytics.png"
                    height="400px"
                    style={{
                      objectFit: 'contain',
                      filter: 'drop-shadow(2px 6px 4px #a2a2a2)',
                    }}
                  />
                </VStack>
              </Stack>
            </VStack>
          </VStack>
        </Container>
      </Box>

      <Box
        style={{scrollMargin: '48px'}}
        ref={platform}
        bg="gray.100"
        w="full"
        alignItems="center">
        <Container maxWidth={{base: '80%', lg: '1024px'}}>
          <VStack
            py="16"
            alignItems="center"
            justifyContent="center"
            w="full"
            space="12">
            <VStack alignItems="center" justifyContent="center" space="4">
              <Heading size="xl" color="indigo.600">
                Our Platform
              </Heading>
              <Text color="gray.600" fontSize="xl">
                Explore Tourexpe's Dynamic Duo
              </Text>
            </VStack>
            <Stack
              direction={{base: 'col', md: 'col', lg: 'row'}}
              alignItems="center"
              space="8"
              w="full">
              <VStack>
                <img
                  src="tourexpe Console.png"
                  alt="Tourexpe Console logo"
                  style={{height: '128px', borderRadius: '12px'}}
                />
              </VStack>
              <VStack space="2" flex="1">
                <Heading size="md" color="indigo.600">
                  Tourexpe Console
                </Heading>
                <Text color="gray.600" fontSize="md">
                  Console is a platform that will allow the department heads in
                  the organization to view and approve trips of the field staff
                  on daily basis. It empowers the organization by keep records
                  of the trips including realtime location and also calculates
                  the travel and dearness allowance for each. Features like
                  report generation provides allowance payment data in the form
                  of sheets for convenient viewing.
                </Text>
              </VStack>
            </Stack>
            <Stack
              direction={{base: 'col', md: 'col', lg: 'row'}}
              alignItems="center"
              space="8"
              w="full">
              <VStack>
                <img
                  src="fieldmate_logo.png"
                  alt="Fieldmate logo"
                  style={{height: '128px', borderRadius: '12px'}}
                />
              </VStack>
              <VStack space="2" flex="1">
                <Heading size="md" color="indigo.600">
                  Fieldmate
                </Heading>
                <Text color="gray.600" fontSize="md">
                  Fieldmate is a mobile app that is used by the on-field staff
                  to record their trips daily. They can record customer details,
                  service engineers can input service types and attach tickets
                  or hotel bills. They can also view their past trips and payments.
                </Text>
              </VStack>
            </Stack>
          </VStack>
        </Container>
      </Box>

      <Box w="full" bg="black" alignItems="center">
        <Container w="full" maxWidth={{base: 'full', xl: '1280px'}}>
          <Stack
            direction={{base: 'col', md: 'col', lg: 'row'}}
            px="10"
            h="20"
            alignItems="center"
            justifyContent={{base: 'center', md: 'center', lg: 'space-between'}}
            space="2"
            w="full">
            <Text color="gray.300">©Tourexpe Inc. All rights reserved.</Text>
            <HStack space="4">
              <Link
                _text={{color: 'white', textDecoration: 'none'}}
                href="/privacy">
                Privacy Policy
              </Link>
              <Link
                _text={{color: 'white', textDecoration: 'none'}}
                href="/legal">
                Terms of Service
              </Link>
              <Link
                onPress={() => window.open('tel:+919109402484')}
                _text={{color: 'white', textDecoration: 'none'}}>
                Contact Us
              </Link>
            </HStack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
