import {useEffect, useState} from 'react';
import {
  Modal,
  Button,
  TextArea,
  VStack,
  HStack,
  FormControl,
} from 'native-base';
import {firestore} from '../firebase.js';

const RejectTripModal = ({
  isRejectTripModalVisible,
  setIsRejectTripModalVisible,
  trip,
}) => {
  const [remarks, setRemarks] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isRejectTripModalVisible) {
      setRemarks(trip.remarks ? trip.remarks : '');
    }
  }, [isRejectTripModalVisible]);

  const handleReject = async () => {
    if (remarks.trim().length === 0) {
      setErrorMessage('Please input some remarks');
      return;
    }
    try {
      firestore.collection('trips').doc(trip.id).update({
        status: 'rejected',
        remarks: remarks,
      });
      onClose();
    } catch (error) {
      console.error('Error updating trip: ', error);
    }
  };

  const onClose = () => {
    setRemarks('');
    setErrorMessage(null);
    setIsRejectTripModalVisible(false);
  };

  return (
    <Modal
      useRNModal={true}
      isOpen={isRejectTripModalVisible}
      onClose={onClose}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Reject Trip</Modal.Header>
        <Modal.Body>
          <VStack space={3}>
            <FormControl isInvalid={errorMessage}>
              <FormControl.Label>Remarks</FormControl.Label>
              <TextArea
                onFocus={() => {
                  setErrorMessage(null);
                }}
                autoFocus
                value={remarks}
                onChangeText={setRemarks}
                placeholder="Input remarks"
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl>
          </VStack>
        </Modal.Body>
        <HStack space={2} px="4" pb="4" justifyContent="flex-end">
          <Button onPress={onClose} colorScheme="secondary">
            Cancel
          </Button>
          <Button onPress={handleReject} colorScheme="warning">
            Reject
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
};

export default RejectTripModal;
