import {
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Switch,
  Text,
  View,
  VStack,
} from 'native-base';
import {useState, useEffect} from 'react';
import {useUser} from '../contexts/userContext';
import {firestore} from '../firebase.js';
import { useOrganization } from '../contexts/OrganizationContext.js';

export default function AccountSettings() {
  const [user] = useUser();
  const [organization] = useOrganization();
  const [organizationName, setOrganizationName] = useState('');
  const [isModeratorEnabled, setIsModeratorEnabled] = useState(false);
  const [isSettingsUpdated, setIsSettingsUpdated] = useState(false);

  useEffect(() => {
    setOrganizationName(organization?.name || '');
    setIsModeratorEnabled(organization?.settings.isModeratorEnabled || false);
  }, [organization]);

  const save = async () => {
    await firestore
      .collection('organizations')
      .doc(user.organizationId)
      .update({
        'settings.isModeratorEnabled': isModeratorEnabled,
      });
    setIsSettingsUpdated(false);
  };

  return (
    <View>
      <VStack p="8" w="2xl">
        <Heading>Account Settings</Heading>

        <VStack w="full" py="8" space="4">
          <HStack alignItems="baseline" space="4">
            <Text fontWeight="bold" color="muted.500">
              Organization name
            </Text>
            <Input flex="1" value={organizationName} />
          </HStack>

          {user.isAdmin && (
            <VStack space="4">
              <Divider />
              <Heading size="sm" color="muted.700">
                Moderator
              </Heading>
              <HStack alignItems="center" space="4">
                <Text fontWeight="400" color="muted.500">
                  Enable this to have another layer of approval before sending
                  trips to the finance department.
                </Text>
                <Switch
                  isChecked={isModeratorEnabled}
                  onToggle={val => {
                    setIsModeratorEnabled(val);
                    setIsSettingsUpdated(true);
                  }}
                />
              </HStack>
            </VStack>
          )}
        </VStack>

        <HStack w="full" justifyContent="flex-end" mt="4">
          {isSettingsUpdated && (
            <Button w="32" onPress={save}>
              Save
            </Button>
          )}
        </HStack>
      </VStack>
    </View>
  );
}
