import {NativeBaseProvider, extendTheme} from 'native-base';
import {AuthProvider} from './auth.js';
import {UserProvider} from './contexts/userContext';
import {OrganizationProvider} from './contexts/OrganizationContext';
import Router from './Router';
import '@fontsource-variable/mulish';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

export default function App() {
  const muiTheme = createTheme({
    typography: {
      fontFamily: 'Mulish Variable',
    },
  });

  const theme = extendTheme({
    components: {
      Popover: {
        baseStyle: {
          zIndex: '5',
        },
      },
    },
    colors: {
      primary: {
        100: '#80c4e6',
        200: '#66b8e0',
        300: '#4dacdb',
        400: '#33a0d6',
        500: '#1a94d1',
        600: '#0088CC',
        700: '#007ab8',
        800: '#006da3',
        900: '#005f8f'
      },
      secondary: {
        100: '#e0dfdf',
        200: '#d1d0cf',
        300: '#c1c0bf',
        400: '#b2b0af',
        500: '#a3a09f',
        600: '#93908f',
        700: '#84817f',
        800: '#74716f',
        900: '#65615f'
      },
    },
    fonts: {
      heading: "Mulish Variable",
      body: "Mulish Variable",
      mono: "Mulish Variable",
    },
  });
  return (
    <NativeBaseProvider theme={theme}>
      <ThemeProvider theme={muiTheme}>
      <CssBaseline />
        <OrganizationProvider>
          <UserProvider>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </UserProvider>
        </OrganizationProvider>
      </ThemeProvider>
    </NativeBaseProvider>
  );
}
